<template>
  <dash-page-new
      :subtitle="$t('Referral')"
      :root="$t('Dashboard')"
      :root-location="localeLink('dashboard')"
      :title="$store.state.settings.pageData.title || $t('Referral')"
      :icon="$store.state.settings.pageData.icon || 'mdi-cogs'"
      :filters="$store.state.settings.pageData.filters || {}"
      :header-route="$store.state.settings.pageData.headerRoute || null"
      :filters-input="$store.state.settings.filtersData || []"
      :side-navigation="$store.state.settings.navigationSelect"
      @filter="$store.state.settings.filtersData = $event"

      navigation-route="settings"
      v-model="navigation"
  >
    <template #header_action>
      <v-btn
          v-if="$store.state.settings.pageData.headerAction"
          @click="$store.state.settings.pageData.headerAction()"
          :color="wsATTENTION"
          class="noCaps"
          dark
      >
        {{ $store.state.settings.pageData.headerActionText }}
      </v-btn>
    </template>

    <template #default>
      <router-view></router-view>
    </template>

  </dash-page-new>
</template>

<script>
export default {
  name: "DashAdminReferral"
}
</script>

<style scoped>

</style>